import * as classNames from "@css/component/RequestFileDownloader.module.scss";
import React, {useState} from "react";

/**
 * @type RequestFileDownloaderProps
 */
type RequestFileDownloaderProps = {
    file: {};
};
const RequestFileDownloader = (props: RequestFileDownloaderProps) => {
    const {
        file,
        ...restProps
    } = props;

    const [fileLocation, setFileLocation] = useState<string>(file[1].url);
    const [fileName, setFileName] = useState<string>(file[1].name);

    const handleDownload = () => {
        fetch(fileLocation)
            .then((response) => response.blob())
            .then((blob) => {
                const url = window.URL.createObjectURL(new Blob([blob]));
                const link = document.createElement('a');

                link.href = url;
                link.setAttribute(
                    'download',
                    fileName,
                );

                document.body.appendChild(link);
                link.click();
                document.body.removeChild(link);
            });
    }

    return (
        <div {...restProps} className={classNames.fileDownloader}>
            <span onClick={handleDownload} className={classNames.link}>{fileName}</span>
        </div>
    );
}

export default RequestFileDownloader;